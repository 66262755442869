.SuccessImport {
  text-align: center;

  ul {
    list-style-type: none;
    text-align: left;
    padding: 0;
    margin-bottom: 30px;
    
    li {
      margin-bottom: 20px;
    }
  }
}