.ImportUsers {
  .zone {
    align-items: center;
    border: 2px dashed #CCC;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 20px;
    width: 60%;
    margin: 0 20%;
    box-sizing: border-box;
  }

  .file {
    background: linear-gradient(to bottom, #EEE, #DDD);
    border-radius: 20px;
    display: flex;
    height: 120px;
    width: 120px;
    position: relative;
    z-index: 10;
    flex-direction: column;
    justify-content: center;
  }
  
  .info {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }

  .size {
    border-radius: 3px;
    margin-bottom: 0.5em;
    justify-content: center;
    display: flex;
    color: #666;
  }

  .name {
    border-radius: 3px;
    font-size: 12px;
    margin-bottom: 0.5em;
    color: #666;
  }

  .progressBar {
    bottom: 14px;
    position: absolute;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .zoneHover {
    border-color: #686868;
  }

  .default {
    border-color: #CCC;
  }

  .remove {
    height: 23px;
    position: absolute;
    right: 6px;
    top: 6px;
    width: 23px;
  }
}