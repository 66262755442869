.UsersList {
  text-align: center;

  .filters {
    margin-bottom: 40px;
  }

  .results {
    th:not(:last-child) {
      cursor: pointer;
    }
  }
}