@import '../../../styles/variables';

.Button {
  text-align: center;
  background-color: #a6727b;
  padding: 15px 20px;
  margin: 0 10px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  color: white;
  font-weight: bold;

  &:hover {
    opacity: 0.8;
  }

  &.cancel {
    background-color: transparent;
    padding: 10px 16px;
    border: 2px solid white;
  }

  &.confirm {
    background-color: $primary-color;
  }
}

@media screen and (max-width: ($min-width)) {
  .Button {
    margin: 16px 0;
  }
}