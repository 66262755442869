@import '../../../styles/variables';

.Nav {
  text-align: center;
  background-color: #fff;
  margin: 20px 0;
}

.Nav * {
  display: inline-block;
  padding: 10px;
  text-decoration: none;
  color: $primary-color;
}