.UserDetail {
  text-align: center;

  table {
    margin: 0 auto;
    text-align: left;

    td {
      padding: 1em;
    }

    tr {
      td:first-child {
        font-weight: bold;
      }
    }
  }
}