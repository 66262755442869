@import '../../../styles/variables.scss';

.Header {
  text-align: center;
  margin: 0 5%;
  width: 90%;

  img {
    width: 100%;
  }
}

@media screen and (min-width: $min-width) {
  .Header {
    margin: 0;
    width: 100%;

    img {
      width: 648px;
    }
  }
}