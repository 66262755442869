@import 'styles/variables';

@font-face {
  font-family: Monserrat;
  src: url('./assets/fonts/Montserrat-Regular.ttf');
}

body {
  margin: 0;
  font-family: Monserrat, sans-serif;
  background-color: $primary-color;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

label {
  font-weight: bold;
}

input[type=text],
input[type=password],
input[type=email],
input[type=checkbox],
select {
  box-sizing: border-box;
}

input[type=text],
input[type=password],
input[type=email],
select {
  width: 100%;
  padding: 14px 3%;
  margin: 8px 0;
  display: block;
  border-radius: 10px;
  border: none;
}

a {
  color: white;
}

table.results {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  tr.tbl-header {
    background-color: $bg-inverse-alpha-color;

    th:nth-last-child(1) {
      width: 80px;
    }
  }

  tr {
    border-bottom: 1px solid $bg-inverse-alpha-color;

    td:nth-last-child(1) {
      display: flex;
      justify-content: space-evenly;
    }
  }
  
  th, td {
    border: 0;
    padding: 1em;
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.overline {
  text-decoration: overline;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.icon-sm {
  width: 24px;
  height: 24px;
}

.flexbox {
  display: flex;
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
  align-items: center;
}

.field-error {
  outline: 2px solid crimson;
}

.wrapper,
.wrapper-sm {
  width: 90%;
  margin: 0 auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 100;
}

.overlay-modal {
  background-color: white;
  color: $primary-color;
  padding: 20px 40px;
  border-radius: 10px;

  ul {
    list-style-type: none;
    padding: 0;
    font-size: 22px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 25px 0;
}

@media screen and (min-width: $min-width) {
  .flexbox {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    width: $min-width;
  }

  .buttons {
    flex-direction: row;
  }
}

@media screen and (min-width: ($min-width-half)) {
  .wrapper-sm {
    width: $min-width-half;
  }
}