@import '../../../styles/variables';

$border-color: #cbd2d9;
$linear-transition: background 0.2s linear;

.Breadcrumbs {
  border: 1px solid $border-color;
  border-radius: 4px;
  display: inline-flex;
  overflow: hidden;

  .crumb {
    color: white;
    background-color: $primary-color;
    outline: none;
    padding: 8px 16px;
    position: relative;
    text-decoration: none;
    transition: $linear-transition;

    &:before,
    &:after {
      background: $primary-color;
      bottom: 0;
      clip-path: polygon(50% 50%, -50% -50%, 0 100%);
      content: "";
      left: 100%;
      position: absolute;
      top: 0;
      transition: $linear-transition;
      width: 12px;
      z-index: 1;
      margin-left: -1px;
    }

    &:before {
      background: $border-color;
      margin-left: 0;
    }

    &:last-child {
      border-right: none;
    }

    &.current {
      background-color: white;
      color: $primary-color;

      &:before,
      &:after {
        background: white;
      }
    }
  }
}

@media screen and (max-width: $min-width) {
  .Breadcrumbs {
    display: none;
  }
}